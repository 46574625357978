import React from 'react';
//import Field from 'material-ui/Field';
import * as utility from '../utility';
import uuid from "uuid";
import $ from 'jquery';
import PropTypes from "prop-types";
var Localstorage = require("store");
let userDetails = "";
class SignUp extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context);
    this.state = {
      errorMessage: ''
    }
    this.handleFormSubmit = this
      .handleFormSubmit
      .bind(this);
    this.handleClick = this
      .handleClick
      .bind(this);

  }
  handleFormSubmit(e) {

    e.preventDefault();

  }
  componentDidMount() {

    $("#tagline").hide();
    
  }
  
  handleClick() {

    if (!$("#signup")[0].checkValidity()) 
      return false;
    
    /* Get Category Data From Api */
    var _this = this;
    var actCode =uuid.v4();

    var q = {

      "first_name": $("#firstname").val(),
      "last_name": $("#lastname").val(),

      "email": $("#email").val(),
      "password": $("#password").val()
    };

    let URL = utility.ApiBaseUrl + "tables/user/rows?access_token=" + utility.ApiToken + "&filters[email][eq]=" + q.email;
    fetch(URL).then(function (response) {
     // console.log(response,"response");
      return response.json();
    })

      .then(function (userlogin) {
       
        if (userlogin.data.length > 0) {
          _this.setState({errorMessage: "User Email already exists."});
        } else {
          var q1 = {
            "first_name": $("#firstname").val(),
            "last_name": $("#lastname").val(),
            "email": $("#email").val(),
            "password": $("#password").val(),
            "title":"Confirm Your Email Address",
            "message":"Thank you for creating an account with us! Please click the link below to verify your email address:",
            "subject":"Confirm Your Email Address"
          };
          let URL1 = utility.ApiBaseUrl + "tables/user/rows?access_token=" + utility.ApiToken;
          fetch(URL1, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
              body: JSON.stringify(q)

            })
            .then(function (response) {
              var data1 = response.json();
              return data1;

            })
            .then(function (data1) {
       //       console.log(data1,"userlogin.data");
              setTimeout(function () {
                _this.setState({errorMessage: 'SignUp Succesfully'});

                $("#firstname").val('');
                $("#lastname").val('');              
                $("#email").val('');
                $("#password").val('')


             // debugger
                _this
                  .props
                  .history
                  .push("/confirmation-mail");
                var url =  utility.GetRoot()+'confirmlogin?' + actCode + '~' + data1.data.id ;
         //           console.log(q1.subject,"q1.subject");
                  utility.SendEmail(q1.email, q1.first_name, "",q1.title,q1.message,url,q1.subject, user => {
             
                  });
              }, 300);

            })
            .catch(function (err) {
           //   console.log(err);
            });
        }

      })

  }
  render() {

    let materialUITheme;

    return (

      <div className="Settings notranslate sign_up">
        <div className="form-header">
          <img height="35px" id="data" src="/assets/startday.png" alt="StartDayInfo"/></div>
        <form className="form-inline" id="signup" onSubmit={this.handleFormSubmit}>
          <div className="card bg-white">
            <div className="card-content">
              <section className="logo text-center">
                <h1>Sign Up</h1>
               
                <h4 id="tagline">Create an account to manage your settings and alarms</h4>
              </section>

              <label>
                {this.state.errorMessage}</label>

              <div className="row">
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  placeholder="First Name"
                  className='formElement'
                  required/>
              </div>
              <div className="row">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  className='formElement'
                  placeholder="Last Name"
                  required/>
              </div>

              <div className="row">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className='formElement'
                  required/>
              </div>
              <div className="row">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  className='formElement'
                  required/>
              </div>
              {/* <div className="row">
                <input
                  id="mobile"
                  name="mobile"
                  type="text"
                  placeholder="Mobile"
                  className='formElement'
                  required/>
              </div> */}
            </div>
            <div className="card-action no-border">
              <button
                id="SignUp"
                name="submit"
                onClick={this
                .handleClick
                .bind(this, 'signup')}
                className="btn btn-primary">
                Sign Up&nbsp;
              </button>
              <br /><br />
              Already have an account? <a href="login" className="color-gray-light">Login Here</a>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SignUp;