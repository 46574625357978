import React, {Component} from 'react';
import * as utility from '../utility';
import $ from 'jquery';
import LoginPop from './LoginPop';
import IframePop from './IframePop';
var bootbox = require("bootbox");
var contentOnly = false;
class FooterOptions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Languages: [],
      UserContent: "",
      UserImage: "",
      languageid: "",
      languageCode: "",
      HasUserImage: false,
      LinksList: []
    }
  }
  componentDidMount(){    
    //console.log('here first');

    contentOnly = utility.getQueryStringValue("contentOnly");
    if (typeof contentOnly == "undefined" || contentOnly == "") 
      contentOnly = false;
    else 
      contentOnly = true;  
  }
  geturl(title)
  {
    utility.GetLinksTableDesktopUrl(title,data => {
      //console.log(data,"<----data123----->>>>>>>");
      return data;
      
    });
  }
  isLogged = ( e)=>{
   // debugger;
    $('#iframe').attr( 'src','#' );
    //utility.isMobileScreen(callback =>{
      var url =e.target.getAttribute("datattr");
      
     // if(callback) {
       // window.location.href = url;
       //}else{ 
        utility.isLog(callback=>{
          if(callback){
            $('#iframe').attr( 'src', url );
            document.getElementById('iframepop').click();
          }else{
            document.getElementById('pop').click();
          }
            
        });
       //}
    //});
   
  }
  
  render() {
    if (contentOnly)
    return null;
  else
    return (
      <div className="section about">
        <div className="container">
          <div className="row">
          <audio id="ado">
                        <source src="/alarm.ogg"/>
                      </audio>
   {/* {this
                .state
                .LinksList
                
                .map(function (details, index) {
                  return   <div className="col-lg-3 col-md-3 text-center">
                  <div className="service-box mt-5 mx-auto first">
                    <i className="fas fa-2x fa-gem mb-3 icon-primary sr-icon-1"></i>
                    <a href={details.desktopurl}>
                    <h5 className="mb-3 icon-primary">{details.title}</h5>
                     
                    </a>
                  </div>
                </div>
                }, this)}  */}
          <a href="#" id="ismobile"></a>
          <a href="javascript:;" id="pop" data-toggle="modal" data-target="#myModal"></a>
          <LoginPop/>
          <a href="javascript:;" id="iframepop" data-toggle="modal" data-target="#Iframe"></a>
          <IframePop />
       <div className="row">
       <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-2x far fa-star mb-3 icon-primary sr-icon-1"></i>
                {/* <a href={utility.getRootUrl() + "page/help"}> */}
                <a href="javascript:;"  onClick={(e)=>this.isLogged(e)}>
                  <h5 datattr="https://startdayupdate.com/planner/pages/redirectionP.php?type=celebrities&startPermision=1" className="mb-3 icon-primary">{utility.GetDictionaryVal("Top Celebrities")}</h5>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 text-center">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-2x fas fa-users mb-3 icon-primary sr-icon-1"></i>
                {/* <a href={utility.getRootUrl() + "page/help"}> */}
                <a href="javascript:;"  onClick={(e)=>this.isLogged(e)} >
                  <h5 datattr="https://startdayupdate.com/planner/pages/redirectionP.php?type=youtubetop&data=world&startPermision=1" className="mb-3 icon-primary">{utility.GetDictionaryVal("Top YouTubers")}</h5>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-2x far fa-newspaper mb-3 icon-primary sr-icon-3"></i>
                <a href="javascript:;"  onClick={(e)=>this.isLogged(e)}>
                {/* <a href={utility.GetLinksTableDesktopUrl("Season's Greetings")}> */}
                  <h5 datattr="https://startdayupdate.com/planner/pages/redirectionP.php?type=groups&data=facebook&startPermision=1" className="mb-3 icon-primary">{utility.GetDictionaryVal("Groups")}</h5>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto">
              <i className="fas fa-2x fab fa-elementor  mb-3 icon-primary sr-icon-3" aria-hidden="true"></i>
                <a href="javascript:;"  onClick={(e)=>this.isLogged(e)}>     
                {/* <a href={utility.GetLinksTableDesktopUrl("Season's Greetings")}> */}
                  <h5 datattr="https://startdayupdate.com/planner/pages/redirectionP.php?type=forms&startPermision=1" className="mb-3 icon-primary">{utility.GetDictionaryVal("Forums")}</h5>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-2x far fa-newspaper mb-3 icon-primary sr-icon-3"></i>
                {/* <a href="javascript:;"  onClick={(e)=>this.isLogged(e)} >
                  <h5 datattr={utility.getRootUrl() + "page/help"} className="mb-3 icon-primary">{utility.GetDictionaryVal("Downloader")}</h5>
                </a> */}
                <a href="http://videos.startdayinfo.com/">
                  <h5 className="mb-3 icon-primary">{utility.GetDictionaryVal("Downloader")}</h5>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto first">
                <i className="fas fa-2x fa-gem mb-3 icon-primary sr-icon-1"></i>
                <a href={utility.GetRoot() + "pub/netw.php"}>
                {/* <a href="http://startdayinfo.com/pub/netw.php"> */}
                  <h5 className="mb-3 icon-primary">{utility.GetDictionaryVal("Internet Speed Test")}</h5>
                </a>
              </div>
            </div>
             <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto first">
                <i className="fas fa-2x fa-code mb-3 icon-primary sr-icon-3"></i>
                <a href={utility.GetRoot() + "pub/weat.php"}>
                
                {/* <a href={utility.GetLinksTableDesktopUrl("Weather Forecast")}> */}
                  <h5 className="mb-3 icon-primary">{utility.GetDictionaryVal("Weather Forecast")}</h5>
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-2x fa-calendar mb-3 icon-primary sr-icon-3"></i>
                <a href={utility.GetRoot() + "events"}>
               
                {/* <a href={utility.GetLinksTableDesktopUrl("Countdowns")}> */}
                  <h5 className="mb-3 icon-primary">{utility.GetDictionaryVal("Countdowns")}</h5>
                </a>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 text-center">
              <div className="service-box mt-5 mx-auto first last">
                <i className="fas fa-2x fa-paper-plane mb-3 icon-primary sr-icon-2"></i>
                <a href={utility.GetRoot() + "pub/exch.php"}
                
               onClick={this.log}>
                {/* <a href={this.geturl("Currency Converter")}> */}
                  <h5 className="mb-3 icon-primary">{utility.GetDictionaryVal("Currency Converter")}</h5>
                </a>
              </div>
            </div>

               {/* <div className="col-lg-3 col-md-3 text-center notranslate">
              <div className="service-box mt-5 mx-auto">
                <i className="fas fa-2x fa-comments mb-3 icon-primary sr-icon-3"></i>
                <a href="/feedback">
             
                  <h5 className="mb-3 icon-primary">{utility.GetDictionaryVal("Feedback")}</h5>
                </a>
              </div>
            </div> */}
          </div>

          </div>
        </div>
      </div>
    );
  }
}

export default FooterOptions;
