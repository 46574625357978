import React, {Component} from 'react';
import * as utility from '../utility';
import $ from 'jquery';
var contentOnly = false;
var shareUrl="";
var  url=utility.GetRoot();
 var title="startydayinfo";
  shareUrl = "http://www.facebook.com/sharer.php?t="+title+"&u="+url;
 
class IframePop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addThisLoaded: false
    }
   
  }
  componentDidMount(){    
  }
 
 

  
  render() {
    return (

            <div className="modal fade" id="Iframe" role="dialog">
                


                <div className="modal-dialog">
                    <div className="modal-content pop">
                        <div className="modal-header">
          
                            <button type="button" className="close" data-dismiss="modal">&times;</button>
                    
                        </div>
                        <div className="modal-next text-center">
                        <div className="addthis_sharing_toolbox"></div>
                        {/* <a href={shareUrl} id="Share" name="submit" class="btn btn-primary">Social Sharing&nbsp;</a> */}
  
      </div>
       
    
                        <div className="modal-body error-list text-center">
                      
                        <iframe id="iframe" src = "/html/menu.htm" width = "950" height = "400">
                                        Sorry your browser does not support inline frames.
                            </iframe>
            </div>
          </div>
        </div>
      </div>

      
    );
  }
}

export default IframePop;



