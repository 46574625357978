import axios from "axios";
import _ from "lodash";
import $ from "jquery";
import Noty from "noty";
import alarms from "./Component/alarms.js";
var schedule = require('node-schedule');
var Localstorage = require("store");
var audio = new Audio('https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3');
// var audio = new Audio('https://cdnjs.cloudflare.com/ajax/libs/ion-sound/3.0.7/sounds/bell_ring.aac')
// var audio = new Audio('https://cdnjs.cloudflare.com/ajax/libs/ion-sound/3.0.7/sounds/staple_gun.ogg');

export const ImgBaseUrl = "https://be.startdayinfo.com";
export const ApiBaseUrl = "https://be.startdayinfo.com/api/1.1/";
export const ApiToken = "sWVZS5winUzHXfMGUElax2Iu7xU3brjM";
export const SiteRoot = "https://startdayinfo.com/";

export function ShowNotification(text, time, type,image_url) {
//console.log(image_url,"image_urlnoty");

	//DOCS: https://ned.im/noty/#/options
//	console.log("Showing notification: " + text);
//	console.log("Showing notification 2: " + time);
	var img = localStorage["UserImage"]
//	console.log("img2: " + img);
	new Noty({
		theme: "semanticui",
		//text:text,
		text: '<div class="activity-item"><div class="img-alarm"><img  id="dataImg" src="https://be.startdayinfo.com'+image_url+'" /></div><span id="dateNoty"><i class="fa fa-calendar" aria-hidden="true"></i>' + text + '</span><br/><span id="timeNoty"><i class="fa fa-clock" aria-hidden="true"></i>' + time + '</span> </br><i class="fas fa-comment"></i><span id="desType">' + type + ' </span> </div>',
		//timeout:5000,
		type: "type",
	}).show();

	if(image_url==null){
		$('img#dataImg').css('display','none');
	
	}
}

export function GetTableData(tableName, callback) {
	var url = ApiBaseUrl + "tables/" + tableName + "/rows?access_token=" + ApiToken;
	axios
		.get(url)
		.then(data => {
			// console.log("######### GOT DATA FOR " + tableName + " ###########");
			// console.log(data, "getTableData");
			return callback(data);
		});
}

export function ShowContentOnly() {
	var contentOnly = getQueryStringValue("contentOnly");
	if (typeof contentOnly == "undefined" || contentOnly == "")
		contentOnly = false;
	else
		contentOnly = true;
	return contentOnly;
}

export function SaveGeoInfo(info) {
	localStorage["GeoInfo"] = JSON.stringify(info);
}

export function GetGeoInfo() {
	var info = localStorage["GeoInfo"];
	if (typeof info != "undefined" && info != null && info.length > 0)
		return JSON.parse(info);
	else
		return {};
}

export function GetLanguages() {
	var info = JSON.parse(localStorage["GeoInfo"]);
	if (typeof info != "undefined" && info != null && info.length > 0) {
		return JSON.parse(info);
	} else {
		//console.log(info.languages[0].iso639_1, "INfosdfsdf");
		var Infolang = info.languages;
		var myLang = _.find(Infolang);
		var GetLanguename = myLang.name;
		localStorage["GetLanguename"] = GetLanguename;
	//	console.log(GetLanguename, "myLang");
		return GetLanguename;
	}

}

export function TranslateNewsLanguages() {
	// 	const translate = require('google-translate-api');

	// translate('Ik spreek Engels', {to: 'ar'}).then(res => {
	//     console.log(res.text,"res.text");
	//     //=> I speak English
	//     console.log(res.from.language.iso,"res.from.language.iso");
	//     //=> nl
	// }).catch(err => {
	//     console.error(err);
	// });
	var bt = require("./lib/bing-translate.js").init({
		client_id: "1063115198678-k2m47rra00vigvh0iak1aa6ufv225250.apps.googleusercontent.com",
		client_secret: "REPFzZjTljc8F8ZCJ9iBb4Ra"
	});

	bt.translate("This hotel is located close to the centre of Paris.", "en", "ar", function (err, res) {
		//console.log(err, res + "----Translate----->");
	});
}
export function GetTableData2(tableName, callback) {
	var url = ApiBaseUrl + "tables/" + tableName + "/rows?access_token=" + ApiToken;
	axios
		.get(url)
		.then(data => {
			// console.log("######### GOT DATA FOR " + tableName + " ###########");
			// console.log(data, "GetTableData2");
			return callback(data);
		});

}

// export function getRootUrl() {
// 	var defaultPorts = {
// 		"http:": 80,
// 		"https:": 443
// 	};

// 	return window.location.protocol + "//" + window.location.hostname +
// 		(((window.location.port) &&
// 			(window.location.port != defaultPorts[window.location.protocol])) ?
// 			(":" + window.location.port) : "") + "/";
// }

var DictionaryCache = [];
export function LoadDictionaryCache(callback) {

	GetTableData("language_dictionarys", data => {
		DictionaryCache = data.data.data;
		// console.log("######################### DictionaryCache ####################");
		// console.log(DictionaryCache, "DictionaryCache ");
		callback();
	});

}

var LinksCache = [];
export function LoadLinksCache(callback) {

	GetTableData2("links", data => {
		LinksCache = data.data.data;
		//console.log(LinksCache, "LinksCache");
		callback(LinksCache);
	});

}
var LinksCacheUrl = "";
export function GetLinksTableDesktopUrl(title, callback) {

	var url = ApiBaseUrl + "tables/links/rows?access_token=" + ApiToken + "&filters[title][eq]=" + title;
	//console.log(url, "url---");
	axios
		.get(url)
		.then(data => {
			LinksCacheUrl = data.data.data[0].desktopurl;
			//console.log("######### GOT DATA FOR " + tableName + " ###########");
			//console.log(LinksCacheUrl, title);
			callback(LinksCacheUrl);
		});

}

// export function LoadLinksCache2(titleName, callback) {

// 	var url = ApiBaseUrl + "tables/" + tableName + "/rows?access_token=" + ApiToken;
// 	axios
// 		.get(url)
// 		.then(data => {
// 			console.log("######### GOT DATA FOR " + tableName + " ###########");
// 			console.log(data, "getTableData");
// 			return callback(data);
// 		});

// }

export function getUserLanguage() {
	var langId = localStorage["UserLang"];
	if (typeof langId == "undefined" || langId == null) {
		langId = 1; //ENGLISH
	}
	//console.log("UserLang: " + langId);
	return parseInt(langId);
}

export function getCookie(c_name) {
	if (document.cookie.length > 0) {
		var c_start = document.cookie.indexOf(c_name + "=");
		if (c_start != -1) {
			c_start = c_start + c_name.length + 1;
			var c_end = document.cookie.indexOf(";", c_start);
			if (c_end == -1) {
				c_end = document.cookie.length;
			}
			return unescape(document.cookie.substring(c_start, c_end));
		}
	}
	return "";
}
export function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
	var expires = "expires=" + d.toUTCString();
	if (window.location.href.indexOf("localhost") > 0)
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	else {
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.startdayinfo.com;path=/";
		document.cookie = cname + "=" + cvalue + ";" + expires + ";domain=.startdayinfo.com;path=/pub";
	}
}

export function getQueryStringValue(key) {
	//console.log("getquery")
	return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
}

export function getUrlParameter(sParam) {
	var sPageURL = window.location.search.substring(1),
		sURLVariables = sPageURL.split("&"),
		sParameterName,
		i;

	for (i = 0; i < sURLVariables.length; i++) {
		sParameterName = sURLVariables[i].split("=");

		if (sParameterName[0] === sParam) {
			return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
		}
	}
}

export function ReloadJs(src) {
	//console.log("&&&&&& RELOADING JS:" + src + " &&&&&&");
	$("script[src=\"" + src + "\"]").remove();
	$("<script>").attr("src", src).appendTo("head");
}

export function GetDictionaryVal(key) {

	var langId = 1;
	if (typeof localStorage["UserLang"] != "undefined")
		langId = parseInt(localStorage["UserLang"]);
	var result = _.find(DictionaryCache, {
		"key": key
	});

	//console.log(result);
	if (typeof result != "undefined") {
		var trans = result.translations.data;

		//console.log(trans);
		var translation = _.find(trans, {
			"language_id": langId
		});
		if (typeof translation != "undefined") {
			
			return translation.value;
		} else
			return key;
	} else {
		return key;
	}
}

export function alarmsData() {

	var alarmJsonArray = [
		{ id: 0, fullname: "7.00", shortname: "AM", Date: "May 13, 2019" },
		{ id: 1, fullname: "7.00", shortname: "PM", Date: "Feb 21, 2019" },
		{ id: 2, fullname: "8.00", shortname: "AM", Date: "Feb 20, 2019" },
		{ id: 3, fullname: "8.10", shortname: "PM", Date: "Mar 29,2019" },
		{ id: 4, fullname: "9.00", shortname: "AM", Date: "Feb 19,2019" },
		{ id: 6, fullname: "10.00", shortname: "PM", Date: "Feb 20,2019" }
		// {id: 7, fullname: "10.30", shortname: "PM",Date:"Jun 29,2019"}

	];
	return alarmJsonArray;
}
export function SetAlarms(callback) {

	_.forEach(localStorage.getItem('Setalarms'), function (index) {

		var year = index.date.substring(0, 4);
		var Month = index.date.substring(5, 6);
		var date = index.date.substring(8, 10);
		var montData = Month - 1
		var time = index.time;
		var hours = time.split(":");
		var minutes = time.split(":");
		var getMinutes = minutes[1].substring(0, 2)

		// var date = new Date(2019, 1, 27, 16, 20, 0);
		/************NoDe  shudule***********/
		var date = new Date(year, montData, date, hours[0], getMinutes, 0);

		var j = schedule.scheduleJob(date, function () {

			var date2 = date.toLocaleString('en-US', { hour12: false });

			// Now we can access our time at date[1], and monthdayyear @ date[0]
			var time = date2.substring(10, 15);
			var mdy = date2.substring(0, 8);
			// We then parse  the mdy into parts
			mdy = mdy.split('/');
			var month = parseInt(mdy[0]);
			var day = parseInt(mdy[1]);
			var year = parseInt(mdy[2]);

			// Putting it all together
			var DateGet = year + '-' + month + '-' + '0' + day;

			var formattedDate = time;

			let URL = ApiBaseUrl + "tables/alarms/rows?access_token=" + ApiToken + "&filters[date][eq]=" + DateGet + "&filters[time][eq]=" + time;

			axios
				.get(URL)
				.then(data => {

					//console.log(data, "Setalarms");
					// 			this.ShowNotification(date2.replace(',', '<label/>'), datalist.data[0].description);
					audio.play();
					return callback(data);
				});
		});

	});

}

export function SaveImageBase64(data, callback) {

	SaveBase64Image(data, user => {
		callback(user);
	});
}

export function SaveBase64Image(filedetails, callback) {
	
	var settings = {
		"async": true,
		"crossDomain": true,
		"url": "https://be.startdayinfo.com/api/1.1/files?access_token=sWVZS5winUzHXfMGUElax2Iu7xU3brjM",
		"method": "POST",
		"headers": {
		  "content-type": "application/json",
		 },
		"processData": false,
		"data": "\n{\"name\":\""+filedetails.file.name+"\",\"title\":\"reter\",\"size\":"+filedetails.file.size+",\"type\":\"" + filedetails.file.type + "\",\"charset\":\"binary\",\"data\":\"" + filedetails.file_base + "\",\"width\":1000,\"height\":1000,\"location\":\"reter\",\"caption\":\"test\",\"user\":1,\"date_uploaded\":\"2019-03-05 16:59:55\"}"
		}
	  $.ajax(settings).done(function (response) {
		//console.log(response);
		callback(response);
	  });
	
}
export function SendEmail(email,user, password,title ,message,usermessage,subject,callback) {

	let Url = "https://utils.nextpageit.net/spencer/AntoniaEmail.php?to=" + email+  "&username=" + user + "&password=" + password+"&usermsg="+usermessage+"&title=" + title +"&message=" + message +"&subject=" + subject
	//	let Url = "http://localhost:3307/sendmail";
	//console.log(email, password, "dhg");
	$.post(Url, {
		"email": email,
		"password": password
	}, user => {
		callback(user);
	});
}

// export function Imahge(data, callback) {

// 	let Url = ApiBaseUrl + "tables/" + alarms + "/rows?access_token=" + ApiToken;
// 	//	let Url = "http://localhost:3307/sendmail";
// 	console.log(email, password, "dhg");
// 	$.post(Url,{
// 		"email": email,
// 		"password": password
// 	})
// 		callback(user);

// }

export function timeFormat(index) {

	var year = index.date.substring(0, 4);
	var Month = index.date.substring(4, 6);
	var data = index.date.split("-")
	console.log('array of date got as- ');
	console.log(data)
	console.log('Year is - ')
	console.log(index.date)
	console.log('month is - ')
	
	// var date = index.date.substring(7, 9);
	var date = data[2]
	var montData = data[1]
	montData = montData-1
	console.log(montData)
	var time = index.time;
	var hours = time.split(":");
	var minutes = time.split(":");
	var getMinutes = minutes[1].substring(0, 2);
	console.log(getMinutes)
	console.log(hours[0])
	// var date = new Date(2019, 1, 27, 16, 20, 0);
	//       /************NoDe  shudule***********/
	var date = new Date(year, montData, date, hours[0], getMinutes, 0);
	//var date = new Date(2019, 3, 25, 17, 42, 0);
	var dateData = {
		id: index.id,
		date: date
	};
	
	return dateData;
}

export function Apidata(id) {
	let URL = ApiBaseUrl + "tables/alarms/rows/?access_token=" + ApiToken + "&filters[id][eq]=" + id;
	const _this = this;
	console.log('url sending as - ',URL)
	// fetch(URL).then(function (response) {
	// 	console.log('sending respone as- ');
	// 	console.log(response.json)
	// 	return response.json();
	
	// 	// console.log(dateData.date)
	
	// 	// console.log(dateData.date)	.then(function (datalist) {

	// 	// console.log(dateData.date)		//console.log(datalist);
	// // date is -')
	// 	// console.log(dateData.date)		callback(datalist);
	// 	})
	// 	.catch(function (error) {
	// 		//console.log(error);
	// 	});
axios.get(URL).then((response)=>{
	console.log(response.data)
	// return response.data.data
}).catch((err)=>{

})
}
export function AlarmsData(alarmlist) {
	console.log("alarm data", alarmlist);
	_.forEach(alarmlist, function (index) {
		// console.log('sending index = ')
		// console.log(index)
		var dateData = timeFormat(index);
	//	console.log("time formate", dateData);
		/************NoDe  shudule***********/
		// console.log('date is -')
		// console.log(dateData.date)
		// console.log('here is date data- ',dateData)
		var j = schedule.scheduleJob(dateData.date, function () {
			// console.log('Scheduled job running')
			// Apidata(dateData.id, function (result) {

			// 	console.log("Alaram result===>",result);
	
				// ShowNotification(result.data[0].date, result.data[0].time, result.data[0].description,result.data[0].image_url);
			// 	audio.play();
			// });
		
				// var apidata =Apidata(dateData.id)
				let URL = ApiBaseUrl + "tables/alarms/rows/?access_token=" + ApiToken + "&filters[id][eq]=" + dateData.id;
	const _this = this;
	// console.log('url sending as - ',URL)
				axios.get(URL).then((result)=>{
					ShowNotification(result.data.data[0].date, result.data.data[0].time, result.data.data[0].description,result.data.data[0].image_url);
					let audio = document.getElementById("ado")
					audio.play();
					// console.log('API RUNNING')
					// console.log(console.logresult.data.data[0])
				})
				
				
			 
	//	console.log("API DATA ==>",apidata);
		});
	//	console.log("API DATA J ==>",j);
	});
}

export function GetData(callback) {
	let URL = ApiBaseUrl + "tables/alarms/rows?access_token=" + ApiToken + "&filters[user_id][eq]=" + Localstorage.get('userid');
	axios
		.get(URL)
		.then(result => {
			AlarmsData(result.data.data);
			callback(result);
		});
}
export function ShowPageLoader() {
	$("#custom-overlay").removeClass("hidden");
  
  }
  
  export function HidePageLoader() {
	$("#custom-overlay").addClass("hidden");
  }
  export function isLog(callback){
	     
  var id = localStorage.getItem('userid');
	if (id) {
		callback(true);
	}
	else{
		callback(false);
	}
  }
  export function isMobileScreen(callback){
	($(document).width() > 500) ?
	callback(false) :
	callback(true)
  }

  export function GetRoot() {
	return window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + "/";
  }