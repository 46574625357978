import React, {Component} from 'react';
import * as utility from '../utility';
import $ from 'jquery';
var contentOnly = false;
var html = '<gcse:searchresults-only></gcse:searchresults-only>';
var myParam="";
class GoogleSearch extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    //var cx = '002269526099653069012:9x_me35w64y';
    //var cx = '012985866104115949700:msbuywzvs_q'; //New id
    var cx='002229317414009066264:ev31vp5aq84';
    var gcse = document.createElement('script');
    gcse.type = 'text/javascript';
    gcse.async = true;
    gcse.src = 'https://cse.google.com/cse.js?cx=' + cx;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(gcse, s);
      const urlParams = new URLSearchParams(window.location.search);
       myParam = urlParams.get('q');
       if(myParam==""){
        window.location.href = "/";
      }
      $('#searchBox').val(myParam);      
    contentOnly = utility.getQueryStringValue("contentOnly");
    //console.log(contentOnly,"ShowContentOnly");

    if (typeof contentOnly == "undefined" || contentOnly == "") 
      contentOnly = true;
    else 
      contentOnly = false;

    }
  
  render() {    
    return (
      <div className="row notranslate">
         <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
        <div className="col-lg-12 mx-auto">
          {contentOnly == true
            ? <a href="#"><img height="35px" src="/assets/startday.png" alt="StartDayInfo"/></a>
            : ""
}
          <form method="get" action="/" autoComplete="on">
           <input type="hidden" name="pb" value="partner-pub-8164915811462913:7313972812"></input>
            <input className="q" type="text" name="q" placeholder="Search" id="searchBox"/>
            <button className="btn" type="submit" name="sa">
              <i className="fas fa-search"></i>
            </button>
          </form>
          <div dangerouslySetInnerHTML={{
            __html: html
          }}></div>
          <a href="#"><img
            height="30"

          //  src={utility.getRootUrl()+ "pub/googlelogo.jpeg"}
        src="https://startdayinfo.com/pub/googlelogo.jpeg"
            id="cse-logo1"
            className="googlelogo"
            alt="Custom Search Engine"/></a>
        </div>
      </div>
    )
  }
}

export default GoogleSearch;