import React, {Component} from 'react';
import * as utility from '../utility';
import $ from 'jquery';
var contentOnly = false;
class LoginPop extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount(){    
  }
  isLoggeds= ( e)=>{
   // debugger;
    $('#iframe').attr( 'src','#' );
    utility.isLog(callback=>{
    if(callback){
     //console.log("if");
      var url =e.target.getAttribute("datattr");
      //document.getElementById.setAttribute(url);
      $('#ifrsame').attr( 'src', url );
      document.getElementById('iframepop').click();
      // let params = 'location=yes,height=570,width=520,scrollbars=yes,status=yes';
      // window.open(e.target.getAttribute("datattr"), '_blank', params);
    }else{
      document.getElementById('pop').click();
    }
      
  });

  }
  
  render() {
    return (
          <div className="modal fade" id="myModal" role="dialog">
              <div className="modal-dialog">
                  <div className="modal-content pop">
                      <div className="modal-header">
                          <button type="button" className="close" data-dismiss="modal">&times;</button>
                          <h4 className="modal-title">Please login</h4>
                      </div>
                      <div className="modal-body error-list text-center">
                      <a href="/login" className="btn btn-success">Login</a>
                      <a href="/sign-up" className="btn btn-danger">Signup</a>
                      </div>
                  </div>
              </div>
          </div>
    );
  }
}

export default LoginPop;
