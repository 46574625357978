import React, { Component } from 'react';
import * as utility from '../utility';
import $ from 'jquery';
import TimePicker from 'react-times';
import axios from "axios";
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import 'react-times/css/classic/default.css';
import { debug, isNullOrUndefined } from 'util';
var Localstorage = require("store");
var errMsg = '';
var imgSrc = ''
var items = [];
var reader = new FileReader();
class AddAlarms extends Component {
  constructor(props) {
    super();

    this.state = {
      HasUserImage: false,
      errorMessage: "",

    }
    this.SaveSettings = this
      .SaveSettings
      .bind(this);
  }
  componentDidMount() {
    if(Localstorage.get('userid') == undefined){
      this.props.history.push("/");
    }
    $('#datepicker').attr('readonly', true);
    $('#Time').attr('readonly', true);
    
  }
 

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
     // console.log('Error: ', error);
    };
  }
  SavewithoutImage(){
  
    var q = {
      "time": $("#Time").val(),
      "description": $("#Desc").val(),
      "date": $("#datepicker").val(),
      "user_id": Localstorage.get('userid'),
      "is_active": $("#checkbox").prop('checked'),
      "image": null,
      "image_url": null

    };
    const _this = this;
    let URL1 = utility.ApiBaseUrl + "tables/alarms/rows?access_token=" + utility.ApiToken

    fetch(URL1, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(q)

    }).then(function (response) {
        var data1 = response.json();
        return data1;

      }).then(function (data1) {
        $("#Time").val('');
        $("#Desc").val('');

        $("#datepicker").val('');
        $("#UserImage").val('');
        utility.HidePageLoader();
        window.location.href = "/alarms";


      })
      .catch(function (err) {
        //console.log(err);
      });

}
  SaveSettings(e) {

    e.preventDefault();
    utility.ShowPageLoader();
    var time =  $("#Time").val();
    // alert(time)
    var datePick = $("#datepicker").val();
    // alert(datePick)
    if(time !=''){
      this.setState({errorMessage : ""});
    }else{
      utility.HidePageLoader();
      this.setState({errorMessage : "Time required"});
      return false;
    }
     if(datePick !=''){
    //   return true;
    this.setState({errorMessage : ""});

    }else{
      utility.HidePageLoader();
      this.setState({errorMessage : "Date required"});
      return false;
    }
    var input = document.getElementById('UserImage');
    if (input != null) {
      var files = input.files;
      if(files.length==0){
        this.SavewithoutImage();

      }
      //console.log(files,"filefgdfgfd")
      if (FileReader && files && files.length) {

        var file = files[0];
       // console.log(file,"file")
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {

          var filedetails = {
            file: file,
            file_base: reader.result
          }

          utility.SaveImageBase64(filedetails, user => {
          
            // var imageid;
            // var  image_url;
            // if(user.data.id==isNullOrUndefined){
            //   imageid = null;
            //   image_url=null
            // }else{
            //   imageid = user.data.id;
            //   image_url=user.data.url;
            // }
            setTimeout(function () {

              // var q = {
              //   "time": $("#Time").val(),
              //   "description": $("#Desc").val(),
              //   "date": $("#datepicker").val(),
              //   "user_id": Localstorage.get('userid'),
              //   "is_active": $("#checkbox").prop('checked'),
              //   "image": user.data.id,
              //   "image_url": user.data.url

              // };
              var q = {
                "time": time,
                "description": $("#Desc").val(),
                "date": datePick,
                "user_id": Localstorage.get('userid'),
                "is_active": $("#checkbox").prop('checked'),
                "image": user.data.id,
                "image_url": user.data.url

              };
              const _this = this;
              let URL1 = utility.ApiBaseUrl + "tables/alarms/rows?access_token=" + utility.ApiToken

              fetch(URL1, {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify(q)

              }).then(function (response) {
                  var data1 = response.json();
                  // alert(data1)
                  return data1;

                }).then(function (data1) {
                  $("#Time").val('');
                  $("#Desc").val('');

                  $("#datepicker").val('');
                  $("#UserImage").val('');
                  utility.HidePageLoader();
                  window.location.href = "/alarms";


                })
                .catch(function (err) {
                 // console.log(err);
                });
            })

          }, 3000);
        };




      }
    }

  }



  render() {
    return (

      <div className="Settings notranslate add-alarm-form">
  <audio id="ado">
                        <source src="/alarm.ogg"/>
                      </audio>
        <form id="UserSettings" method="post" onSubmit={this.SaveSettings}>

          <div>
            <h2>Add Alarm</h2>
            <label>
              {this.state.errorMessage}</label>
            <label>Time :</label><input type="text"  id="Time" placeholder="Time" required />

          </div>
          <div>
            <label>Description :</label>
            <input type="text" id="Desc" placeholder="Desc" required />
          </div>

          <div>

            <label>Date:
            </label><input type="text"  id="datepicker" required />
          </div>
          <div className="ChooseFile">
          <div className="usrimg"> <input id="UserImage" type="file"/></div>


        
        {/* <div className="checkbox_div">   <label>IsActive :</label>
            <input type="checkbox" id="checkbox"  /></div> */}
             <div className="checkbox_div"> 
             <div className="isActive">
            <label className="active-label">IsActive :</label>
            <label className="switch">
              <input type="checkbox" id="checkbox"  />
              <span className="slider round"></span>
            </label>
            </div>
            </div>
          </div>
           
          <input className="btn btn-primary" type="submit" value="save" />
        </form>

      </div>
    );
  }
}

export default AddAlarms;
