import React, { Component } from 'react';
import * as utility from '../utility';
import Timer from './Timer';
import _ from "lodash";
import { debug } from 'util';
import MetaTags from 'react-meta-tags';
import BottomFooter from './BottomFooter';
var orderBy = "";
class Events extends Component {
  constructor(props) {
    super();
    this.state = {
      EventsList: [],
      descriptionOrder: 'asc',
      dateOrder: 'asc',
      countryOrder: 'asc'
            }
    this.getDescriptionOrder = this
      .getDescriptionOrder
      .bind(this);
    this.getCountryOrder = this
      .getCountryOrder
      .bind(this);
    this.getDateOrder = this
      .getDateOrder
      .bind(this);
  }

  componentDidMount() {  
    var urlParam = utility.getUrlParameter("locate");
    //console.log(urlParam,"urlParam");
    if (urlParam != "" && typeof urlParam != "undefined") {
      utility.GetTableData("event", data => {
      const filtered = _.filter(data.data.data, function (o) {
        return o.description.toLowerCase().indexOf(urlParam.toLowerCase()) > -1;
      });
      //console.log(filtered, "filtered");
      this.setState({ EventsList: filtered });
    });
    }
    else {
      utility.GetTableData("event", data => {
        //console.log(data.data.data, "event");
        this.setState({ EventsList: data.data.data, });
      });
    }

  }

  getDescriptionOrder() {
    if (this.state.descriptionOrder == "asc") {
      orderBy = "desc";
    }
    else {
      orderBy = "asc";
    }
    var descriptionData = this.state.EventsList;
    var descriptionByOrder = _.orderBy(descriptionData, function (o) { return (o.description); }, [orderBy]);
    this.setState({
      EventsList: descriptionByOrder, descriptionOrder: orderBy

    });

  }

  getCountryOrder() {

    if (this.state.countryOrder == "asc") {
      orderBy = "desc";
    }
    else {
      orderBy = "asc";
    }
    var countryData = this.state.EventsList;
    var countryByOrder = _.orderBy(countryData, function (o) { return (o.location); }, [orderBy]);
    this.setState({
      EventsList: countryByOrder, countryOrder: orderBy
    });

  }
  getDateOrder() {

    if (this.state.dateOrder == "asc") {
      orderBy = "desc";
    }
    else {
      orderBy = "asc";
    }
    var dateData = this.state.EventsList;

    var dateByOrder = _.orderBy(dateData, function (o) { return (o.date); }, [orderBy]);
    this.setState({

      EventsList: dateByOrder, dateOrder: orderBy

    });

  }
  handleSearch(e) {

    //e.preventDefault();
    // var value = document.getElementById("SearchTextBox").value;

    // var Values = this.state.EventsList;
    // console.log(this.state.EventsList,"this.state.EventsList");

    // const filtered = _.filter(this.state.EventsList, function (o) {
    //   return o.description.toLowerCase().indexOf(value.toLowerCase()) > -1;
    // });
    // console.log(filtered,"filtered");
    // this.setState({

    //   EventsList: filtered

    // });
  }

  Homeclick(){
    window.location.href = "/";
  }
  render() {
    return (
      this.state.EventsList == null ? "" : <div className="section about events">
        <div className="container">

          <div class="row">
            <div class="col-lg-12 mx-auto">

            <div class="btn-home-outer">
              <button class="btn home-btn" type="submit" name="save" id="btnHome" onClick={this.Homeclick.bind(this)}>Home</button>
             </div>

              <h2>Event Timer</h2>

              <a href="javascript:;" onClick={this.getDescriptionOrder}> Description</a> |
<a href="javascript:;" onClick={this.getDateOrder}> Date &amp; Time left</a> |
<a href="javascript:;" onClick={this.getCountryOrder}> Country</a>


              <form id="events-form">
                <input type="text" name="locate" id="SearchTextBox" required/>
                <button class="btn" type="submit" name="save" id="btnEventsearch" onClick={this.handleSearch.bind(this)}>Search</button>
              </form>
            </div>

          </div>
          {this
            .state
            .EventsList
            .map(function (details, index) {
              return <div className="row"><div className="col-md-12 text-center">
                <div className="service-box mt-5 mx-auto first">
                  <div className="description">{details.description}</div>
                  <div className="dates"><b><u>Date:</u></b> {details.date} / <b><u>Country: </u></b>{details.location}</div>
                  <div><img src={utility.ImgBaseUrl + details.image.data.url} /></div>
                  <div className="time-left">Time Left</div>
                  <Timer event={details}></Timer>
                </div>
              </div> </div>
            }, this)}
              
        </div>
        <BottomFooter></BottomFooter>
        <MetaTags>
            <title>Event Timer |Get Latest Information About All Events |StartDayInfo
</title>
            <meta id="meta-description" name="description" content="StartDayInfo allows you to track down event countdowns GLOBALLY such as sports, politics, elections, and others, get the latest information on important events happening around you, Date & Time Left for the event, Country and more.
" />
            <meta id="og-title" property="og:title" content="MyApp" />
            
          </MetaTags> 

      </div>
      
    );
  }
}

export default Events;
