import React, { Component } from 'react';
import GoogleSearch from "./GoogleSearch";
import FooterOptions from "./FooterOptions";
import DynamicImage from "./DynamicImage";
 import News from "./News";
import Rss from "./Rss";
import Weather from "./Weather";
import $ from 'jquery';
import * as utility from '../utility';
import { geolocated } from 'react-geolocated';
import BottomFooter from './BottomFooter';
import * as Geo from "../Geo";

var contentOnly = true;
var Localstorage = require("store");
var user1 = "";

//import * as GoogleTranslate from "../GoogleTranslate";
class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      LangDropdown: "",
      GetWeatherList: [],
      NewsList: [],
      WeatherList: [],
      MaxValue: "",
      ctrycode: "",
      Status: "",
      IsGeolocationAvailable: false,
      contentOnly: false,
      user: ''
    }
  }

  componentWillReceiveProps(newProps) {
       //console.log(newProps);
    if (typeof newProps == "undefined")
      return;
    this.props = newProps;
    var that = this;
    if (this.props.isGeolocationAvailable)
      if (this.props.coords)
        Geo.getUserLocation(this.props.coords.latitude, this.props.coords.longitude, data => {
         // console.log("Geo location updated in local storage.");
          that.setState(data);
        });
    // this.     ? <div>Your browser does not support Geolocation</div>
  }

  componentDidMount() {
    $("body").addClass("homepage");
    // debugger;
    const _this = this;
    user1 = Localstorage.get('userid');
    contentOnly = utility.getQueryStringValue("contentOnly");
    if (typeof contentOnly == "undefined" || contentOnly == "")
      contentOnly = false;
    else
      contentOnly = true;

    utility.TranslateNewsLanguages();
    utility.LoadDictionaryCache(data => {
      this.setState({})
    });
  }
  signout(){
    localStorage.clear();
    window.location.href="/"
  }
  toggle(){
    //$("#nav-bar").show();
    $("#nav-bar").toggle();
  }
  GetSettingsButton() {

    if (contentOnly)
      return null;
    else
      return (
        <div>
        <div className="setting-image desktop" >
        <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
          <div className="Sign-up">
                {user1 > 0  ? <div>
                  <a href="/alarms">Alarm</a><em>|</em>
                <a id="signout" href="javascript:;" onClick={this.signout}>Sign out</a> </div>   :<div><a href="/sign-up">Sign up</a> <em>|</em>
                <a href="/login">Sign in</a> </div>}
                </div>
                <div className="dots">

          <a href={utility.GetRoot() + "settings"}><img id="dotimage" height="35px" src="/assets/dots.png" alt="StartDayInfo" /></a>
      </div>
        </div>    
        <div className="setting-image mobile">
        <div className="Sign-up">
              <div className="mobile-dropdown">
              <span onClick={e =>{this.toggle(e)}}>
              <i  className="fas fa-user-circle"></i></span>
                {user1 > 0  ? 
                  <ul id="nav-bar" >
                    <li><a href="/alarms">Alarm</a></li><em>|</em>
                    <li><a href="/profile">Profile</a><em>|</em></li>
                    <li><a id="signout" href="javascript:;" onClick={this.signout}>Sign out</a></li>
                  </ul>:
                  <ul id="nav-bar" >
                  <li><a href="/sign-up">Sign up</a></li>
                  <li><a href="/login">Sign in</a></li>
                </ul>  }
            </div>
              </div>
              <div className="dots">

        <a href={utility.GetRoot() + "settings"}><img id="dotimage" height="35px" src="/assets/dots.png" alt="StartDayInfo" /></a>
        </div>

        </div>  
       </div>
      )}

  render() {
    return (
      <div>
      <div className="container">

        <Weather status={this.state.Status}></Weather>
        
             {/* <div className="signup">
             <a href="/sign-up">Sign up</a> |
             <a href="/login">Sign in</a>
           </div>
           
            <div>
            <a href="/larms">Alarms</a>
          </div>
        */}

        {this.GetSettingsButton()}
        <GoogleSearch></GoogleSearch>
        <DynamicImage></DynamicImage>
        <FooterOptions></FooterOptions>
        <Rss status={this.state.Status}></Rss>
        {/* <<<<<<< Updated upstream */}
         <News status={this.state.Status}></News> 
        
        {/* {this.state.status=="OK"?<News></News>:<WordNews></WordNews>}
// >>>>>>> Stashed changes */}
</div>  

        <BottomFooter></BottomFooter>
        </div> 
    );
  }
}

// export default Home;
export default geolocated({
  positionOptions: {
    enableHighAccuracy: false
  },
  userDecisionTimeout: 5000
})(Home);
