import React, {Component} from 'react';
import * as utility from '../utility';
var contentOnly = false;
class Header extends Component {
  constructor(props) {
    super();
  }
  componentDidMount() {
    //console.log(this.props);
   // utility.AlarmsData();
    // contentOnly = utility.getQueryStringValue("contentOnly");
    // if (typeof contentOnly == "undefined" || contentOnly == "") 
    //   contentOnly = true;
    // else 
    //   contentOnly = false;
    }
  
  render() {
    if (contentOnly) 
      return null;
    else 
      return (
        <div className="header notranslate">
          <div className="col-md-4">
          <audio id="ado">
                        <source src="/alarm.ogg"/>
                      </audio>
            <a href={utility.GetRoot()}>HOME</a>
          </div>
          <div className="col-md-8"></div>
        </div>
      );
    }
  }

export default Header;
