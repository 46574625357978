import React, {Component} from 'react';
import $ from 'jquery';
import * as utility from '../utility';
import _ from "lodash";
import BottomFooter from "./BottomFooter";
import Header from "./Header";
class ShowPage extends Component {
  constructor(props) {
    super();
    this.state = {
      Content: ""
    }
    utility.LoadDictionaryCache(data => {
      this.setState({response: data})
    });
  }
  componentDidMount() {
   // console.log(this.props);
    var url = this.props.match.params.url;
    var that = this;
    $.get("https://be.startdayinfo.com/api/1.1/tables/pages/rows?access_token=sWVZS5w" +
        "inUzHXfMGUElax2Iu7xU3brjM&filters%5Burl%5D%5Beq%5D=" + url, function (data) {
      var content = "Content for this language not found";
      var page = data.data[0]; //_.find(data.data, {'url': url});
     // console.log(page,"page");
      if (typeof page != "undefined") {
        var langId = utility.getUserLanguage();
        var trans = _.find(page.translations.data, {'language_id': langId});
       // console.log(trans,"tr");
        if (typeof trans != "undefined") {
          if (utility.ShowContentOnly()) //MEANS ITS BEING CALLED FROM MOBILE
          {
            content = trans.content;
          }
          else {
            content = trans.desktopcontent;
            if (content == null) 
              content = trans.content;
            }
         // console.log(document.title,"document.title");
        }
     document.title = page.pagetitle;    
     $("meta[name='description']").attr("content",  page.pagedesc);
    
      }
      that.setState({Content: content});
    });
  }

  render() {
    return (
      <div className="container showpage notranslate">
    
        <Header></Header>
        <div dangerouslySetInnerHTML={{
          __html: this.state.Content
        }}></div>
        <BottomFooter></BottomFooter>

      </div>
    );
  }
}

export default ShowPage;
