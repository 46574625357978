import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import Home from "./Component/Home";
import ShowPage from "./Component/ShowPage";
import Settings from "./Component/Settings";
import Weather from "./Component/Weather";
import Events from "./Component/Events";
import Alarms from "./Component/alarms";
import AddAlarms from "./Component/AddAlarms"
import signup from "./Component/Signup"
import Login from "./Component/login"
import ForgotPassword from "./Component/ForgotPassword"
import Feedback from "./Component/Feedback"
import profile from "./Component/profile"
import confirmationMail from "./Component/confirmationMail"
import confirmlogin from "./Component/confirmlogin"

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/page/:url" component={ShowPage}/>
        
          <Route exact path="/settings" component={Settings}/>
          <Route exact path="/feedback" component={Feedback}/>
          <Route exact path="/profile" component={profile}/>
          <Route exact path="/Weather" component={Weather}/>
          <Route exact path="/events" component={Events}/>
          <Route exact path="/alarms" component={Alarms}/>
          <Route exact path="/add_alarms" component={AddAlarms}/>
          <Route exact path="/sign-up" component={signup}/>
          <Route exact path="/confirmlogin" component={confirmlogin}/>
          <Route exact path="/confirmlogin/:actcode/:name" component={confirmlogin}/>
          <Route exact path="/confirmation-mail" component={confirmationMail}/>
        
          <Route exact path="/login" component={Login}/>          
          <Route exact path="/ForgotPassword" component={ForgotPassword}/>
          <Route path="/" component={Home}/>
   

        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
