import React from 'react';
import Modal from 'react-awesome-modal';
import * as utility from "../utility";
import $ from 'jquery';
var transsummary=[];
export default class GetLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
         
            Summary: []
        }

    }
    SetStateFromProps(props) {
        if (typeof props.data!="undefined") {
            this.setState({Summary: props.data});
        }
    
    }
    componentWillReceiveProps(nextProps) {
        this.SetStateFromProps(nextProps);
    }


    componentDidMount() {
        this.SetStateFromProps(this.props);
    }
    isLogged = ( e)=>{
        // debugger;
        var url = e;
         $('#iframe').attr( 'src','#' );
        // utility.isMobileScreen(callback =>{
          // if(callback){
          //   window.location.href = url;
          //  }else{ 
          document.getElementById('close').click();
            this.props.handlePriceItemClick();
             utility.isLog(callback=>{
               if(callback){
                 $('#iframe').attr( 'src', url );
                 document.getElementById('iframepop').click();
               }else{
                 document.getElementById('pop').click();
               }
                 
             });
           // }
        // });
        
     
       }
    render() {
 
        var content = (
            <div>
                <Modal
                    id="data"
                    visible={this.props.visible}
                    width="364"
           
                    overflow="scroll"
                    height="200"
                    effect="fadeInUp"
                    onClickAway={this.props.handlePriceItemClick}
                    className="modal">
                     <div>

                          
                         </div>
               
                    <div className="modal-body">
                    <a id="close"onClick={this.props.handlePriceItemClick}>
                                <i className="fa fa-times" aria-hidden="true" onClick={this.props.handlePriceItemClick}></i>
                            </a>  
                    <label><i className="fab fa-chrome"></i>
JUMP TO </label>
            <div className="col-md-6">
                <span className="modal-content-section">
                   <a href="/">Home</a>
                </span>
            </div>
              {this
                .state
                .Summary
                .map(function (x, y) {
                  return (
                    <div className="col-md-6">
                          <span className="modal-content-section">
                             <a href="javascript:;" onClick={e => this.isLogged(x.desktopurl)} >{x.title}</a>
                          </span>
                    </div>
                  )
                }, this)}

            </div>
            {/* <button
              id="browser"
              name="browser"
              
              className="btn btn-theme"            
            >Browser</button>  */}

                </Modal>


            </div>

        );
        return (
            <div>
                {content}
            </div>

        )
    }
}