import React from 'react'
import $ from 'jquery';
import * as utility from '../utility';

class ForgotPassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: '',
            password: "",
            userName: ""
        }

    }
    componentDidMount() {
        //console.log(Common.GetQueryStringValue("id"));

    }
    handleFormSubmit(e) {


        e.preventDefault();
    }

    Signin(e) {



        if (!$("#user-Info")[0].checkValidity())
            return;

        var _this = this;
        var q = {
            "email": $("#email").val(),
            "title":"Forgot Your Password!",
            "usermessage":"",
            "message":"Please check your password:",
            "subject":"Forgot Your Password"
        };

        let URL = utility.ApiBaseUrl + "tables/user/rows?access_token=" + utility.ApiToken + "&filters[email][eq]=" + q.email;

       // const _this = this;     // error here variable already declared
        fetch(URL).then(function (response) {
            return response.json();
        })
            .then(function (userlogin) {

                if (userlogin.data.length > 0) {

                    _this.setState({ password: userlogin.data[0].password, userName: userlogin.data[0].first_name });

                    utility.SendEmail(q.email, _this.state.userName, _this.state.password,q.title,q.message,q.usermessage,q.subject, user => {
             
                    });

                    _this.setState({ errorMessage: 'Please check your email for forgot password!' });
                    $("#email").val('');
                }


                else {

                    _this.setState({ errorMessage: 'Email does not exist.' });

                    $("#email").val('');
                }

            })
            .catch(function (error) {
                //console.log(error);
            });

    }
    render() {
        var content = (

            <div className="Settings notranslate forget">
                <div className="form-header">
                    <img height="35px" id="data" src="/assets/startday.png" alt="StartDayInfo" /></div>
                <form id="user-Info" className="login-form" onSubmit={this.handleFormSubmit}>
                    <section className="logo text-center">
                        <h1>
                            Forgot Password
                </h1>
                        <div class="input-row only-btn">
                            <div class="inner-btn flex-center">
                                <a  class="white-link">{this.state.errorMessage}</a>

                            </div>
                        </div>
                    </section>
                    <div className="logindata">
                        <input id="email" type="email" class="" name="email" required placeholder="Please Enter Email"/>
                    </div>


                    <div className="card-action no-border">


                        <button id="submit" onClick={this
                            .Signin
                            .bind(this, '')} type="submit" className="btn btn-primary">Submit</button>


                    </div>
                </form>
            </div>
        );
        return (
            <div>
                {content}
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    if (state.form.wizard !== undefined) {
        return state.form.wizard.values;
    }
    return {};
};

export default ForgotPassword;