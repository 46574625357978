import React, { Component } from 'react';
import * as utility from '../utility';
import $ from 'jquery';
import TimePicker from 'react-times';
import axios from "axios";
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import Noty from "noty";
import 'react-times/css/classic/default.css';
import { debug, isNullOrUndefined } from 'util';
var Localstorage = require("store");
var accode="";
var id="";
class confirmlogin extends Component {
  constructor(props) {
    super();

    this.state = {
      

    }

  }
  componentDidMount() {
   
   
    var query = this.props.location;   
    var res = this.props.location.search.split("&");
    accode=res[0].slice(1)
 id=res[1];
    // console.log(accode)
    // console.log(id)
    this.UpdateConfirmation(id);
    this.getData(id);
  }
  UpdateConfirmation(id) {
     
    var _this = this;

      
    var q = {
      "activation_code": accode,
      "activation_complete":"1",
     
      
    }
    let URL = utility.ApiBaseUrl + "tables/user/rows/"+id+"?access_token=" + utility.ApiToken;
    //console.log(URL);
    fetch(URL, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(q)
    })
        .then(function (response) {
            var data = response.json();
  
            return data;
        })
        .then(function (data1) {        
            
        })
        .catch(function (err) {
           // console.log(err);
        });
  }




  getData(id) {


    let URL = utility.ApiBaseUrl + "tables/user/rows?access_token=" + utility.ApiToken + "&filters[id][eq]=" + id;

    const _this = this;
    fetch(URL).then(function (response) {
      return response.json();
    })
      .then(function (userlogin) {
         // console.log(userlogin.data,"dsfsdfdsfsdfsdfsdfsdfffffffff");

        if(userlogin.data[0].activation_complete == undefined&&userlogin.data[0].activation_complete !="1"){
        debugger;
           $('div#Fail').hide();
          $('.notice').show();
          
        }
        else {
          // Empty Cookie Values
          _this.setState({errorMessage: 'Invalid username or password.'});
      
          $('div#Fail').show();
          $('.notice').hide();
      }
      })
      .catch(function (error) {
       // console.log(error);
      });

    setTimeout(function () {

      $("#email").val('');
      $("#password").val('');

    }, 300);

  }




  render() {
    return (

      <div className="confirmation-data">

        <form id="UserSettings" method="post">
        <div class="container content-container text-center">
        
        <div class="form-header"><img height="35px" id="dataext" src="/assets/startday.png" alt="StartDayInfo"/></div>
            <div class="notice">Thanks for confirming your email. Please <a  href="/login">login here</a>.</div>
   
        <div ID="Fail" runat="server" Visible="false">
            <div class="notice1">The verification code is invalid. Please try again or contact our support team.</div>
        </div>
    </div>
        </form>

      </div>
    );
  }
}

export default confirmlogin;
