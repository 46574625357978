import React, { Component } from 'react';
import * as utility from '../utility';
class Timer extends Component {
  constructor(props) {
    super();
    this.state = {
    }
  }
  componentDidMount() {
    if (typeof this.props.event.date == "undefined") {
     // console.log("Date is undefined in Timder.js cdm");
      return;
    }
    this.getTimer(this.props.event.date);
  }
  getTimer(date_data) {
    const second = 1000,
      minute = second * 60,
      hour = minute * 60,
      day = hour * 24;
    var that = this;
    let countDown = new Date(date_data).getTime(),
      x = setInterval(function () {

        let now = new Date().getTime(),
          distance = countDown - now;

        document.getElementById(that.props.event.id + 'days').innerText = Math.floor(distance / (day));
        document.getElementById(that.props.event.id + 'hours').innerText = Math.floor((distance % (day)) / (hour));
        document.getElementById(that.props.event.id + 'minutes').innerText = Math.floor((distance % (hour)) / (minute));
        document.getElementById(that.props.event.id + 'seconds').innerText = Math.floor((distance % (minute)) / second);

      }, second)
  }

  render() {
    return (

      <div className="container" id="timer">
        <div>
          <span id={this.props.event.id + "days"}></span> days, 
          <span id={this.props.event.id + "hours"}></span> hours, 
          <span id={this.props.event.id + "minutes"}></span> minutes, 
         <span id={this.props.event.id + "seconds"}></span> seconds  </div>
      </div>
    );
  }
}

export default Timer;
