import React, {Component} from 'react';
import * as utility from '../utility';
import * as moment from 'moment'
import cheerio from 'cheerio'
var contentOnly = false;
class Rss extends Component {
  constructor(props) {
    super();
    this.state = {
      LangDropdown: "",
      GetWeatherList: [],
      NewsList: [],
      MaxValue: "",
      Heading: "Top News"
    }

    this.getNews = this
    .getNews
    .bind(this);
  }

  componentWillReceiveProps(newProps) {
  
    if (typeof newProps == "undefined") 
      return;
       
    this.props = newProps;
    this.getNews();
  }

  getNews() 
  {
    let URL="https://news.startdayinfo.com/wp-json/wp/v2/posts?_embed"
    var geoInfo = utility.GetGeoInfo();

    if (this.props.status == "OK") 
      if (typeof geoInfo.alpha2Code != "undefined" && geoInfo.alpha2Code != null && geoInfo.alpha2Code.length > 0) 
        URL='https://news.startdayinfo.com/wp-json/wp/v2/posts?_embed'
    const _this = this;
    fetch(URL).then(function (response) {
      return response.json();
    })
      .then(function (datalist) {
        var countryName = "";
        if (typeof geoInfo != "undefined" && typeof geoInfo.name != "undefined") 
          countryName = geoInfo.name;
        _this.setState({
          NewsList: datalist,
          Heading: "Top News "  + countryName
        });
      })
      .catch(function (error) {
       // console.log(error);
      });
  }

  componentDidMount() {
    contentOnly = utility.getQueryStringValue("contentOnly");
    if (typeof contentOnly == "undefined" || contentOnly == "") 
      contentOnly = false;
    else 
      contentOnly = true;    
    this.getNews();
  }


  render() 
  {
    if (contentOnly)
    return null;
  else
    return (
      <div className="container2">
         <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
        <div className="News">
          <div className="row">
            <h2 id="heading-news">{utility.GetDictionaryVal('Top News')}
            </h2>
            <div className="row news-items">
              {this
                .state
                .NewsList
                .filter((i, index) => (index < 6))
                .map( function (details, index) {
                    

                   let $, videoUrl
                   if(details.format=='video')
                   {
                     $ = cheerio.load(details.content.rendered)
                     videoUrl=$('iframe').attr('src') 
                   }

                  /****** for category*** */
                   //console.log("category name",details._embedded["wp:term"][0][0].name)
                   
                  return <div className="col-md-4 col-xs-12 mb-30" >
                    <div className="news-image" >
                    {
                      details.format=="video" ? <iframe className="news-image" src={videoUrl} ></iframe> : 

                    <a href={details.link}> <img className="news-img"  alt={details.title.rendered} title={details.title.rendered} src={ !details._embedded["wp:featuredmedia"] ? "": details._embedded["wp:featuredmedia"][0].source_url}/> </a>
                    
                    }
                     </div> 
                    <div className="news-item bordr-news">  
                      <a className="news-link" href={details.link}>{details.title.rendered}</a>  
                        <div className="category" >{moment(details.date.toString()).format("MMMM D YYYY")} | <a href={details._embedded["wp:term"][0][0].link}> <span className="category">  {details._embedded["wp:term"][0][0].name} </span> </a>  </div> 
                    <div className="news-text"  dangerouslySetInnerHTML={{__html: details.excerpt.rendered.replace(/ *\[[^\]]*]/g, '').substring(0,200)+'...'}}>
                    
                    </div>
                    <div>
                    <a href={details.link} className="readMore" >read more</a>
                    </div>
                    </div>
                    
                  </div>
                }, this)}
            </div><span>
            <a className="viewMore" href="https://news.startdayinfo.com"> View More </a>
            </span>
          </div> 
         
        </div>    
        
      </div>
    );
  }
}

export default Rss;
