import React, { Component } from "react";
import * as utility from "../utility";
import $ from "jquery";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
var errMsg = "";
class Settings extends Component {
  constructor(props) {
    super();

    this.state = {
      Languages: [],
      UserContent: "",
      UserImage: "",
      languageid: "",
      languageCode: "",
      HasUserImage: false
    };
    utility.LoadDictionaryCache(data => {
      this.setState({});
    });

    this.SaveSettings = this.SaveSettings.bind(this);
    this.RemoveUserImage = this.RemoveUserImage.bind(this);
  }

  componentDidMount() {
    utility.GetTableData("languages", data => {
      this.setState({ Languages: data.data.data });
      //console.log(this.state.Languages, "this.state.Languages");

      // if (typeof localStorage["UserContent"] != "undefined")
      //   document.getElementById("UserContent").value =
      //     localStorage["UserContent"];

      if (typeof localStorage["UserLang"] != "undefined")
        document.getElementById("UserLang").value = parseInt(
          localStorage["UserLang"]
        );
    });

    if (
      typeof localStorage["UserImage"] !== "undefined" &&
      localStorage["UserImage"].length > 0
    ) {
      //console.log("Found UserImage")
      var imgSrc = localStorage["UserImage"];
      this.setState({ HasUserImage: true, imgSrc: imgSrc });
    }
  }

  SaveSettings(e) {
    e.preventDefault();
    var input = document.getElementById("UserImage");
    if (input != null) {
      var files = input.files;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          localStorage["UserImage"] = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
    } else {
      //NO FILE SELECTED
      localStorage["UserImage"] = "";
    }
    var content = this.state.UserContent;

    if (content.length > 0) localStorage["UserContent"] = content;
    else localStorage["UserContent"] = "";

    var langId = document.getElementById("UserLang").value;
    localStorage["UserLang"] = langId;
    var code = $("#UserLang option:selected").data("code");
    var codeName = $("#UserLang option:selected").attr("type");
    utility.setCookie("lang", code.toUpperCase(), 365);
    utility.setCookie("langName", codeName, 365);
    window.location.href = "/#/";
  }

  RemoveUserImage() {
    localStorage["UserImage"] = "";
    this.setState({ HasUserImage: false });
  }

  render() {
    return (
      <div className="Settings notranslate day-info">
        {/* {!this.props.isGeolocationAvailable
          ? <div>Your browser does not support Geolocation</div>
          : !this.props.isGeolocationEnabled
            ? ""
            : this.props.coords
              ? this.getUserLocation(this.props.coords.latitude, this.props.coords.longitude)
              : ""} */}

        <form id="UserSettings" method="post" onSubmit={this.SaveSettings}>
          <h2>{utility.GetDictionaryVal("Settings & Activity Reminder")}</h2>

          <label>{utility.GetDictionaryVal("Choose a language")}</label>
          <select id="UserLang">
            {this.state.Languages.map(item => (
              <option
                data-code={item.language_code}
                type={item.language}
                key={item.id}
                value={item.id}
              >
                {item.language}
              </option>
            ))}
          </select>
          {/* <label>{utility.GetDictionaryVal("Content")}</label> */}
          <div
            id="content"
            style={{
              backgroundColor: "#f7f6f6",
              width: "100%",
              float: "left",
              padding: "1"
            }}
          >
            <label>{utility.GetDictionaryVal("Content")}</label>
          </div>
          <p>Enter Text Below</p>
          <CKEditor
            editor={ClassicEditor}
            data={localStorage["UserContent"]}
            onInit={editor => {
              // You can store the "editor" and use when it is needed.
              // data=localStorage['UserContent']
              console.log(editor);
              console.log("Editor is ready to use!", editor);
            }}
            onChange={(event, editor) => {
              const data = editor.getData();
              // console.log({ event, editor, data });
              this.setState({
                ...this.state,
                UserContent: data
              });
            }}
            onBlur={(event, editor) => {
              console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              console.log("Focus.", editor);
            }}
          />
          {/* <textarea id="UserContent" /> */}
          {/* <label>{utility.GetDictionaryVal("Image")}</label> */}
          {!this.state.HasUserImage ? (
            <input id="UserImage" type="file" />
          ) : (
            <div className="user-image-thumb-container">
              {/* <img className="user-image-thumb" src={this.state.imgSrc}></img> */}
              <br />
              <a href="javascript:void(0)" onClick={this.RemoveUserImage}>
                Remove
              </a>
            </div>
          )}
          <input
            className="btn btn-primary settingbtn"
            type="submit"
            value={utility.GetDictionaryVal("Save")}
          />
        </form>
      </div>
    );
  }
}

export default Settings;
