import $ from "jquery";
import * as utility from "./utility";
import _ from "lodash";

export function getUserLocation(lat, lng, callback) {
	let Url_Get_Postalcode = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyCPwKWAwYOEUgMOR6jbDyLjUTadlo9YmMk";
	fetch(Url_Get_Postalcode, {
		method: "GET"
	})
		.then(response => response.json())
		.then(json => {
			//console.log(json, "---json---");
			let addr = json.results[0].address_components;
			let addr2 = json.results[2].address_components;
			let addr_postalcode = json.results[0].formatted_address;
			//console.log("Current User Location");
			let addrCity = getAddrVal(addr, "locality");
			let addrCountry = getsAddrVal(addr2, "country");
			let addrCountryCode = getAddrVal2(addr, "country");
			let adminL1 = getAddrVal(addr, "administrative_area_level_1");
			if (addrCity.trim() === "") {
				addrCity = adminL1;
			}
			let adminL2 = getAddrVal(addr, "administrative_area_level_2");
			let adminL3 = getAddrVal(addr, "administrative_area_level_3");
			let addrState = adminL1;
			if (addrState.trim() === "") {
				addrState = adminL2;
			}
			if (addrState.trim() === "") {
				addrState = adminL3;
			}
			let URL = "";

			URL = "https://restcountries.eu/rest/v2/alpha/" + addrCountryCode;
			fetch(URL).then(function (response) {
				return response.json();
			})
				.then(function (response) {
			//		console.log(response, "resposne -api");
					utility.SaveGeoInfo(response);
					localStorage.setItem("Userlatitude", lat);
					localStorage.setItem("UserLongitude", lng);
					var geoInfo = utility.GetGeoInfo();
			//		console.log(geoInfo, "geoInfo");
					utility.GetTableData("languages", data => {
						var dbLanguages = data.data.data;
						// var found_names = $.grep(table_langarr, function (v) {
						// 	if (typeof geoInfo.languages[0].iso639_1 != "undefined" && geoInfo.languages[0].iso639_1 != null && geoInfo.languages[0].iso639_1.length > 0) {
						// 		if ((geoInfo.languages[0].iso639_1).toUpperCase() === (v.language_code).toUpperCase()) {
						// 			//langId = v.id;
						// 			langName = geoInfo.languages[0].name;
						// 		}
						// 		return langName;
						// 	}
						// });
						var geoLang = geoInfo.languages[0].iso639_1;
						var langName = geoInfo.languages[0].name;
					
						var availableLang = _.find(dbLanguages,function(o){return o.language_code.toLowerCase()==geoLang;});
						
						if(addrCountry=="Ireland"){
							langName  ="English"
					   }							
						if (typeof availableLang == "undefined" || availableLang == null || availableLang == "") {
			//				console.log("Language not available in backend. Just using in Google Translate. Can't set in Settings.");
							//TODO SET IN SETTINGS IF FOUND
						}
			//			console.log("Final langname based on geo: "+langName);
						if(utility.getCookie("langName").length<=0)
						{
							//utility.ShowNotification("Loading content based on your location now...");
							utility.setCookie("langName", langName, 365);
							setTimeout(function(){
								window.location.reload();
							},2000);
						}
						//RELOADING JS NOT WORKING RIGHT NOW
						//utility.ReloadJs("GoogleTranslate.js");
						
						callback({
							ctrycode: addrCountryCode,
							Status: json.status
						});
					}
					);
					///NOT REQUIRED TO LOAD HERE
					// var geo_url = utility.SiteRoot + "GoogleTranslate.js?c=a";
					// fetch(geo_url).then(function (response) {
					// 	return response.json();
					// })
					// 	.then(function (response) {
					// 	})
					// 	.catch(function () {
					// 		console.log("err");
					// 	});
					
				})
				.catch(function () {
			//		console.log("err");
				});
			
		});
}

export function getsAddrVal(addr, key) {
	try {
		let address_details = addr.find(function (e) {
			if (e.types[0] === key) {
				// console.log(e.types[0]);
				return e.types[0];
			}
			return null;
		});
		if (address_details != null)
			return address_details.short_name && address_details.long_name;
		else
			return "";
	} catch (err) {
	//	console.log(err);
	}
}

export function getAddrVal(addr, key) {
	try {
		let address_details = addr.find(function (e) {
			if (e.types[0] === key) {
				// console.log(e.types[0]);
				return e.types[0];
			}
			return null;
		});
		if (address_details != null)
			return address_details.long_name;
		else
			return "";
	} catch (err) {
	//	console.log(err);
	}
}

export function getAddrVal2(addr, key) {
	try {
		let address_details = addr.find(function (e) {
			if (e.types[0] === key) {
				return e.types[0];
			}
			return null;
		});
		if (address_details != null)
			return address_details.short_name;
		else
			return "";
	} catch (err) {
	//	console.log(err);
	}
}