import React, {Component} from 'react';
import * as utility from '../utility';
var contentOnly = false;
class Weather extends Component {
  constructor(props) {
    super();
    this.state = {
      LangDropdown: "",
      GetWeatherList: [],
      NewsList: [],
      MaxValue: "",
      Status: ""
    }
  }

  getWeather() {
    let url = 'https://api.openweathermap.org/data/2.5/weather?lat=' + localStorage.getItem('Userlatitude') + '&lon=' + localStorage.getItem('UserLongitude') + '&units=metric&appid=3978396639fe7474aa52ae3247de5007';
    fetch(url)
      .then(response => response.json())
      .then(data => {

        if (typeof data != "undefined" && typeof data.main != "undefined") 
          this.setState({WeatherList: data.name, MaxValue: data.main.temp});
        else 
          this.setState({WeatherList: "", MaxValue: "0"});
       
      })
  }

  componentDidMount() {
    this.setState({Status: this.props.status});
    if (this.props.status == "OK") {
      this.getWeather();
    } else {
      this.setState({Status: this.state.WeatherList});
      this.getWeather();
    }

    contentOnly = utility.getQueryStringValue("contentOnly");
    if (typeof contentOnly == "undefined" || contentOnly == "") 
      contentOnly = false;
    else 
      contentOnly = true; 
  }
  render() {
    if (contentOnly)
    return null;
  else
    var geoInfo = utility.GetGeoInfo();
    if (this.props.status == "") 
      return (
        <div style={{
          height: "40px"
        }}></div>
      );
    else 
      return (
        <div className="container notranslate">
          <div className="row">
          <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
            <div className="col-md-3">
              <p id="demo"></p>
              <i className="fa fa-map-marker weather-header" id="marker" aria-hidden="true"></i>
              <label id="addrCity" className="weather-header">
                {geoInfo.alpha2Code}</label>
              <label id="city" className="weather-header">{this.state.WeatherList}</label>
              <i className="fa fa-cloud weather-header" id="cloud" aria-hidden="true"></i>
              <label id="city" className="weather-header">{this.state.MaxValue}'C</label>
            </div>
          </div>
        </div>
      );
    }
  }

export default Weather;