import React, { Component } from 'react';
import * as utility from '../utility';
import $ from 'jquery';
import TimePicker from 'react-times';
import axios from "axios";
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import Noty from "noty";
import 'react-times/css/classic/default.css';
import { debug, isNullOrUndefined } from 'util';
var Localstorage = require("store");
var errMsg = '';
var imgSrc = ''
var items = [];
var reader = new FileReader();
class Feedback extends Component {
  constructor(props) {
    super();

    this.state = {
      

    }
    this.Sendfeedbak = this
      .Sendfeedbak
      .bind(this);
  }
  componentDidMount() {
   
  }
  Sendfeedbak(e) {

debugger;

    if (!$("#UserSettings")[0].checkValidity())
        return;

    var _this = this;
    var q = {
       "email":"iflexemail@gmail.com",
        "UserEmail": $("#email").val(),
        "Name": $("#name").val(),
        "Message": $("#message").val(),
        "title":"Feedback Email",
        "message_data":"Please check Feedback message.",
         "subject":"Feedback Email"
    };

    //console.log(q,"q");
debugger;
                utility.SendEmail(q.email,q.Name,q.UserEmail,q.title,q.message_data,q.Message,q.subject,  user => {         

                });
                _this
                .props
                .history
                .push("/")
                new Noty({
                    type: 'warning',
                    layout: 'bottomRight',
                    text: 'Your feedback was sent successfully.',
                    timeout: 5000,
                    }).show();
               
               
            }



      




  render() {
    return (

      <div className="Settings notranslate add-alarm-form">
  <audio id="ado">
                        <source src="/alarm.ogg"/>
                      </audio>
        <form id="UserSettings" method="post" onSubmit={this.Sendfeedbak}>

          <div>
            <h2>Feedback</h2>
            <label>
              {this.state.errorMessage}</label>
            <label>Name :</label><input type="text"  id="name" placeholder="Name" required />

          </div>
          <div>
            <label>Email :</label>
            <input type="email" id="email" placeholder="Email" required />
          </div>

          <div>

            <label>Message:


            </label>
         
            <textarea   id="message" class="text" cols="60" rows ="10" required />
          </div>
      
          <input className="btn btn-primary" type="submit" value="Send" />
        </form>

      </div>
    );
  }
}

export default Feedback;
