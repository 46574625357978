import React from 'react';
//import Field from 'material-ui/Field';
import * as utility from '../utility';
//import { Field, reduxForm, autofill } from 'redux-form';
import $ from 'jquery';
import PropTypes from "prop-types";
var Localstorage = require("store");
let userDetails = "";
let updateId =Localstorage.get('userid');
class profile extends React.Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context);
    this.state = {
      errorMessage: ''
    }
    this.handleFormSubmit = this
      .handleFormSubmit
      .bind(this);
      this.handleSubmitClick = this
      .handleSubmitClick
      .bind(this);

  }
  handleFormSubmit(e) {

    e.preventDefault();

  }
  componentDidMount() {
      this.getData();

    $("#tagline").hide();
  }


  handleSubmitClick(id) {
      
    var _this = this;

      
    var q = {
      "first_name": $("#firstname").val(),
      "last_name": $("#lastname").val(),
      "email":$("#email").val(),
      "password":$("#password").val(),    
      
    }
    let URL = utility.ApiBaseUrl + "tables/user/rows/"+id+"?access_token=" + utility.ApiToken;
    //console.log(URL);
    fetch(URL, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(q)
    })
        .then(function (response) {
            var data = response.json();
  
            return data;
        })
        .then(function (data1) {
            _this.setState({ errorMessage: 'Record updated successfully.' });      
         
            _this
            .props
            .history
            .push("/")
        })
        .catch(function (err) {
      //      console.log(err);
        });
  }


getData() {
    var _this = this;


let URL = utility.ApiBaseUrl + "tables/user/rows?access_token=" + utility.ApiToken + "&filters[id][eq]=" +Localstorage.get('userid') ;
//console.log(URL,"URL");
    fetch(URL).then(function (response) {
      return response.json();
    })
      .then(function (searchlist) {
  //      console.log(searchlist,"searchlist");
        if (searchlist.data.length > 0) {
    //        console.log(searchlist.data[0].first_name);
           
            $("#firstname").val(searchlist.data[0].first_name); 
            $("#lastname").val(searchlist.data[0].last_name); 
            $("#email").val(searchlist.data[0].email); 
            $("#password").val(searchlist.data[0].password); 

          }
      })
      .catch(function (error) {
      //    console.log(error);
      });     
      
  }
  render() {

    let materialUITheme;

    return (

      <div className="Settings notranslate sign_up">
        
        <div className="form-header">
          <img height="35px" id="data" src="/assets/startday.png" alt="StartDayInfo"/></div>
        <form className="form-inline" id="signup" onSubmit={this.handleFormSubmit}>
          <div className="card bg-white">
            <div className="card-content">
              <section className="logo text-center">
                <h1>Profile</h1>
               
              </section>
              <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
              <label className="updatemsg">
                {this.state.errorMessage}</label>

              <div className="row">
                <input
                  id="firstname"
                  name="firstname"
                  type="text"
                  placeholder="First Name"
                  className='formElement'
                  required/>
              </div>
              <div className="row">
                <input
                  id="lastname"
                  name="lastname"
                  type="text"
                  className='formElement'
                  placeholder="Last Name"
                  required/>
              </div>

              <div className="row">
                <input
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  className='formElement'
                  readOnly
                  required/>
              </div>
              <div className="row">
                <input
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Password"
                  className='formElement'
                  required/>
              </div>
              
            </div>
            <div className="card-action no-border">
              <button
                id="SignUp"
                name="submit"
                onClick={this
                    .handleSubmitClick
                    .bind(this, updateId)} 
                className="btn btn-primary"
                >
                Save&nbsp;
              </button>
            
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default profile;