import React, {Component} from 'react';
import axios from "axios";
import * as utility from "../utility";
import $ from 'jquery';
import _ from "lodash";
var Localstorage = require("store");
class DynamicImage extends Component {
  constructor(props) {
    super();

    this.state = {
      imgSrc: ""
    };
  }

  componentDidMount() {
    //NOT TO LOAD IMAGE AND TEXT WHEN SHOWING SEARCH RESULTS
    if (window.location.href.indexOf("q=") > 0) 
    {
      $("#cse-logo1").hide();
      return;
    }
    var imgSrc;
    var content;
    var ImageTranslation;
    var that = this;

    //ALWAYS GET DATA FROM API
    var url = utility.ApiBaseUrl + "tables/featured_content/rows?access_token=" + utility.ApiToken + "&filters[target][eq]=value1&depth=2";
   //console.log(url,"url");
    axios
      .get(url)
      .then(data => {
     //   console.log("GETTING DynamicImage ###########")
       // console.log(data.data.data[0])
       // imgSrc = utility.ImgBaseUrl + data.data.data[0].image.data.url;

        var langId = 1;
        if (typeof localStorage["UserLang"] != "undefined" && localStorage["UserLang"].length > 0) {
          langId = parseInt(localStorage["UserLang"]);
         // console.log("Found UserLang: " + langId)
        }
        var translations = data.data.data[0].translations.data;
        //console.log(translations);
        var translation = _.find(translations, {"language_id": langId});
        if (typeof translation != "undefined") 
          content = translation.body;
          imgSrc = utility.ImgBaseUrl + translation.images.data.url;
        //console.log(content,"content");
        //console.log(imgSrc,"ImageTranslation");
        if (typeof localStorage["UserImage"] != "undefined" && localStorage["UserImage"].length > 0) {
          //console.log("Found UserImage")
          imgSrc = localStorage["UserImage"];
        }
        if (typeof localStorage["UserContent"] != "undefined" && localStorage["UserContent"].length > 0) {
          //console.log("Found UserContent")
          content = localStorage["UserContent"];
        }

        that.setState({imgSrc: imgSrc, Content: content});
      });

  }
  render() {
    return (
      <div className="row dynamic-image notranslate">
        <img src={this.state.imgSrc}></img>

        <div id="ShowContent" dangerouslySetInnerHTML={{
            __html: this.state.Content
          }}></div>
      </div>
    );
  }
}

export default DynamicImage;