import React from 'react';
export default class Audios extends React.Component{
    constructor(props){
        super(props)
        this.state={
            file:props.src
        }
    }
    render(){
        return(
            <audio autoPlay>
                 <source src={this.state.file} type="audio/ogg"></source>
            </audio>
        )
    }
}