import React, { Component } from 'react';
import * as utility from '../utility';
import $ from 'jquery';
import TimePicker from 'react-times';
import axios from "axios";
// use material theme
import 'react-times/css/material/default.css';
// or you can use classic theme
import Noty from "noty";
import 'react-times/css/classic/default.css';
import { debug, isNullOrUndefined } from 'util';
var Localstorage = require("store");
var errMsg = '';
var imgSrc = ''
var items = [];
var reader = new FileReader();
class confirmationMail extends Component {
  constructor(props) {
    super();

    this.state = {
      

    }

  }
  componentDidMount() {
   
  }





  render() {
    return (
      <div className="confirmation">
         <div class="form-header"><img height="35px" id="dataText" src="/assets/startday.png" alt="StartDayInfo"/></div>
<h3 class="text-center register-acc">Thank you for creating an account!</h3>
        <form id="UserSettings" method="post">
        <div class="container">
      
           
    <div class="register-container Confimation">
    We have sent you a confirmation email to verify your email address. If the email is sent to your Spam folder, it is best to move the email to your inbox to prevent possible loss of notifications.
    </div>
   </div>
        </form>

      </div>
    );
  }
}

export default confirmationMail;
