import React, { Component } from 'react';
import * as utility from '../utility';
var contentOnly = false;
class BottomFooter extends Component {
  constructor(props) {
    super();
  }

  componentDidMount() {
 // console.log(this.props,"this.props");
    utility.GetData(function(data){
     // console.log(data, 'datalist');

    });
    contentOnly = utility.getQueryStringValue("contentOnly");
    if (typeof contentOnly == "undefined" || contentOnly == "") 
      contentOnly = false;
    else 
      contentOnly = true;
  }

  render() {
    if (contentOnly)
      return null;
    else

      return (
        <div id="bottom-footer">
          <div className="inner-wrap clearfix">
            <h6 className="social">
              <a href={utility.GetRoot() + "page/about"}>
                <span className="footer_span">{utility.GetDictionaryVal("About Us")}</span>
              </a>
              |
            <a href={utility.GetRoot() + "page/terms"}>
                <span className="footer_span">
                  {utility.GetDictionaryVal("Terms")}</span>
              </a>
              |
            <a href={utility.GetRoot() + "page/privacy"}>
                <span className="footer_span">{utility.GetDictionaryVal("Privacy")}</span>
              </a>
              |

                <a href="/feedback">
                
                <span className="footer_span">Feedback</span>
                </a>
                |
            <a href={utility.GetRoot() + "page/help"}>
                <span className="footer_span">{utility.GetDictionaryVal("Help")}</span>
              </a>
            </h6>
            <h6 className="copyright">
              {utility.GetDictionaryVal("© StartDay 2019")}
            </h6>
            <div></div>
            <p></p>
          </div>
        </div>
      );
  }
}

export default BottomFooter;
