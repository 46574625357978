import React from 'react';
import $ from 'jquery';
import * as utility from '../utility';
var Localstorage = require("store");
// const cookies = new Cookies();
class Login extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      errorMessage: ''
    }
    this.handleFormSubmit = this
      .handleFormSubmit
      .bind(this);
    this.handleClick = this
      .handleClick
      .bind(this);
  }
  handleFormSubmit(e) {

    e.preventDefault();

  }
  Clear() {
    $("#email").val('');
    $("#password").val('');

  }
  componentDidMount()
  {
    $("body").addClass("hide-sharing");
    Localstorage.remove('userid');
  }

  handleClick(id) {
    debugger;
    // if (!$("#user-Info")[0].checkValidity())     return false;

    var q = {

      "email": $("#email").val(),
      "password": $("#password").val()
    };

    let URL = utility.ApiBaseUrl + "tables/user/rows?access_token=" + utility.ApiToken + "&filters[email][eq]=" + q.email + '&filters[password][eq]=' + q.password;

    const _this = this;
    fetch(URL).then(function (response) {
      return response.json();
    })
      .then(function (userlogin) {
      
  
        if(userlogin.data.length==0){
          
          _this.setState({errorMessage: ' You have entered an invalid login credentials.'});
          $("#email").val('');
         $("#password").val('');
        }
        if(userlogin.data.activation_complete == undefined && userlogin.data[0].activation_complete !="1"){
      
          _this.setState({errorMessage: 'Plaese activate the account'});
        
        }
      
       
        if (userlogin.data.length > 0 ) {
          // Set Cookies
         debugger;
          Localstorage.set('userid', userlogin.data[0].id, {path: '/'});

         // console.log(Localstorage.get('userid'));
          _this.setState({errorMessage: ''});
          
          _this
          .props
          .history
          .push("/")
 
        } 
        else {
          Localstorage.remove('userid');

    }
      })
      .catch(function (error) {
        //console.log(error);
      });

    // setTimeout(function () {
    //   // _this.setState({errorMessage: ' You have entered an invalid login credentials.'});
    //   $("#email").val('');
    //   $("#password").val('');

    // }, 300);

  }
  render() {
    return (
      <div className="Settings notranslate">
        <div className="form-header">
          <img height="35px" id="data" src="/assets/startday.png" alt="StartDayInfo"/></div>
        <form id="user-Info" className="login-form" onSubmit={this.handleFormSubmit}>
          <section className="logo text-center">
            <h1>
              Login
            </h1>
          </section>

              <label className="loginerror">
                {this.state.errorMessage}</label>
          <div className="logindata">
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Email"
              floatingLabelText="Email"
              required/>
          </div>

          <div className="logindata">
            <input
              id="password"
              name="password"
              type="password"
              placeholder="Password"
              floatingLabelText="Password"
              required/>

          </div>

          <div className="card-action no-border">
            <button
              id="Login"
              name="submit"
              onClick={this
              .handleClick
              .bind(this, 'user-Info')}className="btn btn-primary">
              Login
            </button>
            <br/><br/>
          
            <a href="/forgotpassword">Forgot Password</a>
            <br/><br/>
            Don't have an account?&nbsp;
            <a href="/sign-up">Sign Up Here</a>

          </div>
        </form>
      </div>
    );
  }
}
export default Login;
