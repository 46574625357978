import React, { Component } from 'react';
import { Link } from "react-router-dom";
import * as utility from "../utility";
import axios from "axios";
import $ from 'jquery';
import _ from "lodash";
import moment from 'moment'
import TranDet from '../Component/GetLink';
import LoginPop from './LoginPop';
import IframePop from './IframePop';
import {connect} from 'react-redux'
import action from '../actions/action'
//import { Button, Confirm } from 'semantic-ui-react'
// var alarm = require('alarm');
import Noty from "noty";
import Audios from './Audios';
var schedule = require('node-schedule');
var contentOnly = true;
var audio = new Audio('https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3');
var Localstorage = require("store");
class alarms extends Component {

  constructor(props) {
    super();
    this.state = {
      visible: false,
      Alarmdata: [],
      getLinksData: [],
      dataget: [],
      data: [],
       open: false,
      errorMessage: '',
      imgSrc: "",
      PopUpLoader: "hidden"
    }
    this.closeModal = this
      .closeModal
      .bind(this);
      this.handleDeleteClick = this
            .handleDeleteClick
            .bind(this);
      this.isLogged = this.isLogged.bind(this);
  }
  open = () => this.setState({ open: true })
  close = () => this.setState({ open: false })
  openModal() {

    this.setState({
      visible: true,
    });

  }

  closeModal() {

    this.setState({
      visible: false
    });
  }

  SetStateFromProps(props) {
    if (typeof props.Trans !== "undefined") {

      this.setState({ Trans: props.Trans });

    }
  }

  componentWillReceiveProps(nextProps) {
    this.SetStateFromProps(nextProps);
    
  }

  componentDidMount() {
    utility.isMobileScreen(callback =>{
     if(callback){
        $("#desktop-nav").hide();
        $("#mobileNav").show();
      }else{
        $("#mobileNav").hide();
        $("#desktop-nav").show();
      }
    });
     
    //  utility.ShowNotification("2019/1/27, 12:20:00".replace(',', '<label/>'),"datalist.data[0].description");  
    // console.log((Localstorage.get('image')),"manisha")
    if(Localstorage.get('userid') == undefined){
      this.props.history.push("/");
    }
    this.getData();
    this.getlinks();
    this.SetStateFromProps(this.props);
    var imgSrc;
    var content;
    var ImageTranslation;
    var that = this;

    //ALWAYS GET DATA FROM API
    var url = utility.ApiBaseUrl + "tables/featured_content/rows?access_token=" + utility.ApiToken + "&filters[target][eq]=value1&depth=2";

    axios
      .get(url)
      .then(data => {

        // imgSrc = utility.ImgBaseUrl + data.data.data[0].image.data.url;

        var langId = 1;
        if (typeof localStorage["UserLang"] != "undefined" && localStorage["UserLang"].length > 0) {
          langId = parseInt(localStorage["UserLang"]);

        }
        var translations = data.data.data[0].translations.data;

        var translation = _.find(translations, { "language_id": langId });
        if (typeof translation != "undefined")
          content = translation.body;
        imgSrc = utility.ImgBaseUrl + translation.images.data.url;

        if (typeof localStorage["UserImage"] != "undefined" && localStorage["UserImage"].length > 0) {

          imgSrc = localStorage["UserImage"];
        }
        if (typeof localStorage["UserContent"] != "undefined" && localStorage["UserContent"].length > 0) {

          content = localStorage["UserContent"];
        }

        that.setState({ imgSrc: imgSrc, Content: content });
      });
    this.setState({ imgSrc: imgSrc });
  }

  getlinks() {
    let URL = utility.ApiBaseUrl + "tables/alarms_drop_list/rows?access_token=" + utility.ApiToken;
    const _this = this;
    fetch(URL).then(function (response) {
      return response.json();
    })
      .then(function (datalist) {
        if (datalist.data.length > 0) {

          _this.setState({ getLinksData: datalist.data });
        }
      })
      .catch(function (error) {
       // console.log(error);
      });
  }
  getData() {
    const _this = this;
    utility.GetData(function (datlist) {
      _this.setState({ Alarmdata: datlist.data.data });
    });

  }
  signout(){
    localStorage.clear();
    window.location.href="/"
  }
  GetSettingsButton() {
    //this.state.getLinksData
    if (contentOnly)
      return null;
    else
      return (
        <div className="setting-image">
          <a href={utility.GetRoot() + "settings"}><img id="dotimage" height="35px" src="/assets/dots.png" alt="StartDayInfo" /></a>
        </div>
      )
  }
  isLogged = ( e)=>{
    // debugger;
    var url = e;
     $('#iframe').attr( 'src','#' );
    // utility.isMobileScreen(callback =>{
      // if(callback){
      //   window.location.href = url;
      //  }else{ 
         utility.isLog(callback=>{
           if(callback){
             $('#iframe').attr( 'src', url );
             document.getElementById('iframepop').click();
           }else{
             document.getElementById('pop').click();
           }
             
         });
       // }
    // });
    
   }

   handleDeleteClick(id) {
     
    var _this = this;
    var n = new Noty({
      text: 'Are you sure to delete the record?',
      type: 'confirm',
      id:"noty-main",
      // layout: 'center',
      //theme: 'defaultTheme',
      buttons: [
        Noty.button('YES', 'btn btn-primary Noty', function () {
          let URL_order =utility.ApiBaseUrl + 'tables/alarms/rows/' + id + '?access_token=' +  utility.ApiToken;
          console.log(URL_order);
          fetch(URL_order, {
              method: 'DELETE',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
              },
          })
              .then(function (response) {
                  var data1 = response.json();
      
                  return data1;
              })
              .then(function (data1) {
                  _this.setState({ errorMessage: 'Record deleted successfully.' });
                  setTimeout(function () {
                        _this.getData();
                      _this.setState({ errorMessage: '' });
                    
                  }.bind(this), 1000)
      
              })
              .catch(function (err) {
                 // console.log(err);
              });
              n.close();
        }),
    
        Noty.button('NO', 'btn btn-primary Noty', function () {
          
            n.close();
        })
      ]
    }).show();
    
}
  render() {
    var daat = ""

    return (<div>
       <div id="desktop-nav">
          <div className="alarm-menu">
            {/* <Audios src={this.props.src}/> */}
          <div className="">
                <a href="/">Home</a><em>|</em>                 
                <a href="/profile">Profile</a>
                {this.state.getLinksData.map(function (x, y) {
                  return (
                    <spam>
                       <em>|</em> <a href="javascript:;" onClick={e => this.isLogged(x.desktopurl)}>{x.title}</a>
                  </spam>
                  )
                }, this)}
                {/* <a id="signout" onClick={this.signout}>Sign Out</a>*/}</div> 
        </div>
        <a id="signout" onClick={this.signout}>Sign Out</a>
          </div>
   
      <div className="container">
      <a href="#" id="ismobile"></a>
          <a href="javascript:;" id="pop" data-toggle="modal" data-target="#myModal"></a>
          <LoginPop/>
          <a href="javascript:;" id="iframepop" data-toggle="modal" data-target="#Iframe"></a>
          <IframePop />
        <div className="mainAlarm">
          <div className="alarm-inner">
          <div id="mobileNav" >
          <a onClick={() => this.openModal()}>
              <img id="dotimage" height="35px" src="assets/Droplist.png" alt="StartDayInfo" />
            </a>
          </div>
          <h2>Alarm(s)</h2>
            {/* <h2>Alarms</h2> */}
            {this.state.errorMessage}
            <div className="scroll">

              {this
                .state
                .Alarmdata
                .map(function (x, y) {
            //console.log(x.image_url,"image_url")
                  var ischecked = false;
if(x.image_url== null){
  $(".description").addClass("intro");
  // $("#image").remove();
}
                  if (x.is_active == 1) {
                    ischecked = true;
                  }
                  else {
                    ischecked = '';
                  }

                  return (
                    <div className="row">
                      <div className="col-md-12">

                        <div className="alarmdata" >
                      <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
                          <div className="col-md-9 col-xs-9" key={x.id}>
                            <i
                              className={x.time.substring(0, 5) >= "16:00"
                                ? "fa fa-moon"
                                : "fa fa-sun"}></i>
                    
                            <label class={ischecked === "true" ? "time checked" : "time notcheckd"}>
                              {(x.time.substring(0, 5))}<em> {x.time.substring(0, 5) >= "12:00" ? "PM" : "AM"}</em></label>
                            <div className="date">
                              <div className={x.image_url === null ? "alarm-img hide" : "alarm-img show"}   >
                              {/* <div className="alarm-img" id="image" > */}

                                <img src={"https://be.startdayinfo.com"+x.image_url} id="GetImg"></img>
                              </div>
                              <div class="description">
                                <span className="date-inner">
                                  {x.date}</span>
                                <span className="description-inner">{x.description}
                                </span>

                              </div>

                            </div>
                          </div>
                          <div className="col-md-3 col-xs-3 text-right">
                            <label class="switch">

                              <input type="checkbox" checked={ischecked} />
                              <span class="slider round" id="round"></span>
                             
                            </label>
                          </div>
                       
                          <label  onClick={this
                                                                    .handleDeleteClick
                                                                    .bind(this, x.id)}
                                                               >   <i className="glyphicon glyphicon-trash"></i></label>
                        </div>
                      </div>
                    </div>
                  )
                }, this)}

            </div>

            <TranDet shopPopup={this.state.visible == true ? "show" : "hide"} visible={this.state.visible} handlePriceItemClick={this.closeModal} data={this.state.getLinksData} />
            <a href="/add_alarms" id="Plus" className="btn btn-primary">
              <i class="fas fa-plus-circle"></i>
            </a>
          </div>
        </div>
        {/* <input
            className="btn btn-primary"
            type="submit"
         Value="save" /> */}
      </div></div>
    );
  }
}

// const mapStateToProps = (state)=>{
// return{
//   src:state.src
// }
// }
// export default connect(mapStateToProps,null) (alarms);
export default alarms