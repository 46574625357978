import React, {Component} from 'react';
import * as utility from '../utility';
var contentOnly = false;
class News extends Component {
  constructor(props) {
    super();
    this.state = {
      LangDropdown: "",
      GetWeatherList: [],
      NewsList: [],
      MaxValue: "",
      Heading: "Top News"
    }
  }

  componentWillReceiveProps(newProps) {
    if (typeof newProps == "undefined") 
      return;
    this.props = newProps;
   // console.log(newProps);
    this.getNews();
  }

  getNews() {
    let URL = 'https://newsapi.org/v2/top-headlines?sources=techcrunch&apiKey=f6dbfaf5956b4a7bb' +
        '3c2babcacb6b1bb';
    var geoInfo = utility.GetGeoInfo();

    if (this.props.status == "OK") 
      if (typeof geoInfo.alpha2Code != "undefined" && geoInfo.alpha2Code != null && geoInfo.alpha2Code.length > 0) 
        URL = 'https://newsapi.org/v2/top-headlines?country='+ geoInfo.alpha2Code+ '&category=business&apiKey=f6dbfaf' +
            '5956b4a7bb3c2babcacb6b1bb';
      // URL = 'https://newsapi.org/v2/top-headlines?country=' + geoInfo.alpha2Code+
    // '&category=business&apiKey=f6dbfaf5956b4a7bb3c2babcacb6b1bb';
    const _this = this;
    fetch(URL).then(function (response) {
      return response.json();
    })
      .then(function (datalist) {
        var countryName = "";
        if (typeof geoInfo != "undefined" && typeof geoInfo.name != "undefined") 
          countryName = geoInfo.name;

        _this.setState({
          NewsList: datalist.articles,
          Heading: "Important News " + countryName
        });
      })
      .catch(function (error) {
        //console.log(error);
      });
  }

  componentDidMount() {
    contentOnly = utility.getQueryStringValue("contentOnly");
    if (typeof contentOnly == "undefined" || contentOnly == "") 
      contentOnly = false;
    else 
      contentOnly = true;   
      
    this.getNews();
  }

  render() {
    if (contentOnly)
    return null;
  else
    return (
      <div className="container">
        <div className="News2">
        <audio id="ado">
                        <source src="/alarm.ogg" type="audio/ogg"/>
                      </audio>
          <div className="row">
            <h2 id="heading-news2">{utility.GetDictionaryVal(this.state.Heading)} {/* Top News */}
            </h2>
            <div className="row news-items2">
              {this
                .state
                .NewsList
                .filter((i, index) => (index < 4))
                .map(function (details, index) {
                  return <div className="col-md-3 col-xs-12">
                    <div className="news-image2">
                    <div className="news-item2">  
                      <img className="news-img2" alt="product" src={details.urlToImage}/></div>
                    <div className="news-text2">
                      <a className="news-link2" href={details.url}>{details.title}</a>
                    </div>
                    </div>
                  </div>
                }, this)}
            </div>
          </div>  
        </div>    

      </div>
    );
  }
}

export default News;
